.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  width: 100%;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.Name-input {
  width: 100%;
  height: 56px;
  border-radius: 4px;
  position: relative;
  text-align: center;
  background-color: rgba(255,255,255,0.3);
  transition: 0.3s all;
}
.Name-input:hover {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}
.button_for_api_1 {
  position: relative;
  width: 180px;
  height: 60px;
  margin: 20px;
  line-height: 60px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  transition: 0.1s;
  border: 1px solid darkred;
  cursor: pointer;
  background-color: black;
}
.button_for_api_1:hover{
  border: 1px solid transparent;
  background-color: red;
  transition-delay: 0.5s;
  animation: animate 0.2s steps(8) forwards;
}
.break_word_in_length{
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #eee;
 text-align: justify;
}
.button_for_api_1 span{
  background-color: white;
  color: black;
  padding-left: 2%;
  padding-right: 2%;
}
.fitfy_percent_class{
 float: left;
  width: 45%;
  margin-left: 2.5%;
}
